// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/codebuild/output/src086723239/src/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-post-js": () => import("/codebuild/output/src086723239/src/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-tag-page-js": () => import("/codebuild/output/src086723239/src/src/templates/tag-page.js" /* webpackChunkName: "component---src-templates-tag-page-js" */),
  "component---src-pages-blog-tags-index-js": () => import("/codebuild/output/src086723239/src/src/pages/blog/tags/index.js" /* webpackChunkName: "component---src-pages-blog-tags-index-js" */),
  "component---src-pages-404-js": () => import("/codebuild/output/src086723239/src/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("/codebuild/output/src086723239/src/src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-blog-index-js": () => import("/codebuild/output/src086723239/src/src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-index-js": () => import("/codebuild/output/src086723239/src/src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("/codebuild/output/src086723239/src/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-hannin-js": () => import("/codebuild/output/src086723239/src/src/pages/projects/hannin.js" /* webpackChunkName: "component---src-pages-projects-hannin-js" */),
  "component---src-pages-projects-index-js": () => import("/codebuild/output/src086723239/src/src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */)
}

