module.exports = [{
      plugin: require('/codebuild/output/src086723239/src/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-136495071-1"},
    },{
      plugin: require('/codebuild/output/src086723239/src/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src086723239/src/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/codebuild/output/src086723239/src/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://wilsonmitchell.me"},
    },{
      plugin: require('/codebuild/output/src086723239/src/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/codebuild/output/src086723239/src/src/cms/cms.js"},
    },{
      plugin: require('/codebuild/output/src086723239/src/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
